@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import "../css/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');

body {
    font-family: "Comfortaa", sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.heading-font {
    font-family: 'STIX Two Text', serif;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
    line-height: 18px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}

.heading-1 {
    font-size: 29px;
    font-weight: 500;
}

.font-bold {
    font-weight: $font-bold;
}

.download-buttonStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 9px 18px;
    background-color: white;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: none;

    &:hover {
        background-color: #f0f0f0;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }
}

.download-iconStyle {
    margin-right: 8px;
    font-size: 16px;
}

.font-medium {
    font-weight: $font-medium;
}

.font-light {
    font-weight: $font-light;
}

.font-regular {
    font-weight: $font-regular;
}

a {
    text-decoration: none;
    transition: 0.4s;

    &:hover {
        color: $color-secondary !important;
    }
}

.text-brand {
    color: $color-primary;
}

.text-brand-dark {
    color: $color-secondary;
}

.nav-link {
    padding: 2px !important;
}

.btn-brand-2 {
    background-color: gray !important;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-weight: 500;
    height: 38px;
    border: 0;
    padding: 7px 27px;
    line-height: 26px;

    &:hover {
        // background-color: #2DD99FBA !important;
        transform: scale(1.1);
        color: #fff !important;
    }

    &.btn-sm {
        height: 34px;
        padding: 4px 19px;
        font-weight: 500;
    }
}

.pop:hover {
    transform: scale(1.1);
}

.download-buttonStyle:hover {
    transform: scale(1.1);
    background-color: #2DD99F;
    color: white;
}

.btn-brand-1 {
    background-color: $color-primary !important;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-weight: 500;
    height: 38px;
    border: 0;
    padding: 7px 27px;
    line-height: 26px;

    &:hover {
        background-color: #0AFAA9 !important;
        transform: scale(1.1);
        color: #fff !important;
    }

    &.btn-sm {
        height: 34px;
        padding: 4px 19px;
        font-weight: 500;
    }
}

.btn-outline-brand {
    @extend .btn-brand-1;
    background-color: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;

    &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: $color-primary;
    border-color: $color-primary;
}

form {
    label {
        font-size: 14px;
        font-weight: $font-bold;
        margin-bottom: 4px !important;
    }
}

// left menu css
.layout-veritcle-menu {
    display: flex;
    min-height: 100vh;

    .verticle-menu {
        background-color: $color-secondary;
        width: 260px;
        height: calc(100vh - 34px);
        position: sticky;
        top: 34px;
        padding-top: 74px;
        z-index: 4;


        .menu-list {
            .menu-item {
                transition: 0.3s;
                display: flex;
                gap: 10px;
                align-items: center;
                padding: 7px 16px;
                height: 44px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 8px;
                color: #fff;
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;

                &:hover,
                &:focus {
                    opacity: 1;
                    background-color: #fff;
                    color: #000 !important;
                }

                .menu-icon {
                    width: 22px;
                    height: 22px
                }
            }

            .active {
                opacity: 1;
                background-color: #fff;
                color: #000 !important;

                svg {
                    -webkit-filter: invert(100%);
                    filter: invert(100%);
                }
            }

            .dropdown-toggle {
                @extend .menu-item;
                border: 0;
                background-color: transparent;


                &::after {
                    border: 0;
                }

                &.show {
                    background-color: $color-primary-light;
                    opacity: 1;
                }
            }

            .dropend {
                text-align: center;
            }

            .dropdown-menu {
                left: -3px !important;
                border-radius: 0 6px 6px 0;
                background-color: $color-primary;

                .dropdown-item {
                    color: #fff;
                    font-size: 14px;
                    padding: 9px 13px;
                    margin-bottom: 3px;

                    &:hover {
                        background-color: #ffffff2b;
                        color: #ffffff !important;
                    }
                }
            }
        }

        .tooltip-inner {
            background-color: #343434 !important;
        }
    }

    .app-body {
        width: calc(100% - 110px);
        padding-top: 110px;
    }
}

// header 

.site-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    border-bottom: 1px solid #F2F2F2;

    .header-nav {
        .navbar {
            padding: 0;
        }
    }

    .header-nav {
        background-color: #fff;
        padding: 6px 15px;

        .v-devider {
            display: inline-block;
            width: 1px;
            background: #525252;
            height: 20px;
            margin: 0 15px;
        }
    }

    .profile-dd {
        .dropdown-menu {
            min-width: 200px;
            top: 68px;
            border: 0;
            box-shadow: 0px 3px 10px #0000002b;

            .dropdown-item:hover a,
            .dropdown-item:hover .icon {
                color: #000 !important;
            }
        }

        .btn {
            background-color: #F8F8F8;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 0;

            &::after {
                border: 0;
            }

            border-radius: 30px;
            padding: 5px 5px;
        }

        .icon {
            width: 18px;
            height: 18px;
            margin-right: -4px;
            color: #4b4b4b;
        }
    }

    .help-btn {
        background-color: #F8F8FF;
        font-size: 14px;
        color: $color-primary;
        font-weight: $font-medium;
        height: 48px;
        padding: 8px 15px;
    }
}

// App Body 
.app-body {
    background: #fff;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-dropdown {
    .dropdown-toggle {
        background-color: transparent;
        border-radius: 30px;
        border: 1px solid #000;
        color: #000;
        font-size: 14px;

        &::after {
            border: 0;
        }
    }

    .dropdown-menu {
        min-width: 250px;
        font-size: 14px;
        box-shadow: 0px 4px 11px #00000036;
        border: 0;

        .form-select {
            font-size: 14px;
        }
    }
}

.rdrCalendarWrapper {
    .rdrDayToday {
        .rdrDayNumber span:after {
            background-color: $color-primary;
        }
    }

    .rdrDayNumber {
        font-weight: $font-medium;
        font-size: 14px;
    }

    .rdrWeekDay {
        color: $color-primary;
    }
}

.table-search-box {
    svg {
        width: 24px;
        height: 24px;
        stroke: #cdcdcd;
    }

    .input-group-text {
        background-color: #fff;
        border-right: 0;
        font-size: 14px;
    }

    .form-control::placeholder {
        font-size: 14px;
        color: #b3b1b1;
    }

    .form-control {
        border-left: 0;
        padding-left: 0;
    }
}


.loginbg-image {
    background-image: url(../images/lognibg.jpg);
    background-size: cover;
    background-position: bottom;
}

.login,
.image {
    min-height: 100vh;
}

.box {
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    background-color: #fff;
}

.updown {
    background-color: #EDFBF0;
    color: #4CD964 !important;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
    font-weight: bold;
}

.downStyle {
    background-color: #d05b4861;
    color: red !important;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
    font-weight: bold;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
    border-color: #ddd;
}

.action-btns {
    .btn {
        padding: 5px 10px;
        height: auto;
        font-size: 14px;
        line-height: 20px;
    }
}

.dashboardup {

    span {
        color: #7F7F7F;
        font-size: 12px;

    }

    h3 {
        color: #0F1010;
        font-size: 21px;
        margin-bottom: 0;
        font-weight: 900;
        margin-top: 9px;
    }
}

.datestyle {
    background-color: #F5F5F5;
    border: none;
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
    height: 38px;
    padding-left: 16px;
    padding-right: 15px;
}

.boxheading {
    font-size: 19px;
    color: #1F1F1F;
    font-weight: 600;
}

hr {
    color: #dbdbdb;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    font-weight: 700;
}

.stationheder {
    background-color: #FFB82F;
    color: #fff;
    font-weight: 600;
}

.greenheader {
    background-color: #4CD964;
    color: #fff;
    font-weight: 600;
}

.border-one {
    border: 1px solid #F2F2F2;
}

.border3 {
    border-radius: 3px;
}

.box {
    .card {
        border: none !important;
        min-height: 247px;
    }
}

.text-success {
    color: #4CD964 !important;
    font-weight: 500;
}

thead th {
    background-color: #F5FAFF !important;
    font-weight: 600;
    font-size: 15px;
}

.table-style-1 {

    // border: 1px solid #ddd;
    td {

        .btn-edit,
        .btn-delete {
            font-size: 21px;
            color: #7F7F7F;
            padding: 0 0;
            line-height: 26px;
            margin: 0 5px;
        }
    }
}

.tableborder {
    border: 1px solid #EBEBEB;
}

td {
    font-size: 14px;
}

.btn-success {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    ;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-success:hover {
    background-color: #1cbf88;
    border: 1px solid #1cbf88;
}

.editicon {
    font-size: 21px;
    color: #7F7F7F;
}

.infoicon {
    font-size: 14px;
    color: #7F7F7F;
}

.trashicon {
    font-size: 17px;
    color: red;
}

label {
    font-weight: 600;
}

.modal-header {
    border: 0px;
    padding-bottom: 0px;
}

.nav-underline .nav-link.active {
    color: $color-primary !important;
}

.nav-underline .nav-link {
    color: $color-secondary !important;
}

.infoicon {
    font-size: 16px;
    color: #7F7F7F;
}

.btn-outline-success {
    color: $color-primary !important;
    border-color: $color-border !important;
}

.btn-outline-success:hover {
    background-color: $color-primary !important;
    color: #fff !important;
}

.pagination {
    margin-bottom: 0;
    margin-right: 0 !important;

    .page-link {
        color: $color-primary;
    }

    .page-item.active {
        .page-link {
            background-color: $color-primary;
            border-color: $color-primary;
            color: #fff;
        }
    }
}

.headingClass {
    font-weight: 1000;
}

// added
.station-card {
    height: 250px;
    /* Adjust height as necessary */
    width: 100%;
}

.card-body-scroll {
    max-height: 280px;
    /* Adjust height to fit within the card */
    overflow-y: auto;
}


.point-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.timeSlot {
    font-size: 13px;
}

.point-status-available {
    color: green;
}

.point-status-charging {
    color: orange;
}

.point-status-faulted {
    color: red;
}

.layout-veritcle-menu .dashboard {
    width: 18px !important;
    margin-left: 2px;
}

.layout-veritcle-menu .dashboard {
    width: 18px !important;
    margin-left: 2px;
}


.button-loader {
    height: 15px;
    margin-right: 5px;
}


.pac-container {
    z-index: 9999 !important;
}

.layout-veritcle-menu .verticle-menu .menu-list .menu-item:hover svg {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.layout-veritcle-menu .verticle-menu .menu-list .menu-item:focus svg {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}


// responsive css
@media (max-width: 767px) {
    .site-header .brand-logo{
        width: 65px;height: 40px;
        }
        
    .layout-veritcle-menu{
        .verticle-menu{position: fixed;left: -290px;
            transition: 0.4s;box-shadow: 11px 0 16px #0000002e;padding-top: 27px;z-index: 8;}
        .app-body{width: 100%;padding-top: 65px;}
        .verticle-menu .menu-list .menu-item{margin-bottom: 0;height: 42px;}
    }
    .mobile-menu-btn{line-height: 16px;padding: 7px;}
    .icon-menu-open{display: block;}
    .icon-menu-close{display: none;}
    .mobile-menu{
        .verticle-menu {left:0}
        .mobile-menu-btn{
            .icon-menu-open{display: none;}
            .icon-menu-close{display: block;}
        }
    } 
    .date-range-row{
        .left-option{
            padding: 15px 8px !important;width: 90px !important;
            button{padding: 5px !important;font-size: 12px !important;}
        }
        .calendar-col{padding: 15px 8px !important;}
    }
}

  /* Styles for iPads */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
       only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        .site-header .brand-logo{
            width: 65px;height: 40px;
            }
            
        .layout-veritcle-menu{
            .verticle-menu{position: fixed;left: -290px;transition: 0.4s;box-shadow: 11px 0 16px #0000002e;padding-top: 27px;}
            .app-body{width: 100%;padding-top: 65px;}
            .verticle-menu .menu-list .menu-item{margin-bottom: 0;height: 42px;}
        }
        .mobile-menu-btn{line-height: 16px;padding: 7px;}
        .icon-menu-open{display: block;}
        .icon-menu-close{display: none;}
        .mobile-menu{
            .verticle-menu {left:0}
            .mobile-menu-btn{
                .icon-menu-open{display: none;}
                .icon-menu-close{display: block;}
            }
        } 
  
}